import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import { useUserStore } from '@/store/user.store'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import("../views/loginView.vue"),
    meta: {
      title: '登录页面', //--路由元信息
      transition: 'animate__fadeIn' //--过渡动画
    }
  },
  {
    path: '/resetpws',
    name: 'resetpws',
    component: () => import("../views/resetpwsView.vue"),
    meta: {
      title: '首页', //--路由元信息
      transition: 'animate__bounceIn' //--过渡动画
    },
  },
  {
    path: '/index',
    name: 'index',
    component: () => import("../views/layout/layoutAll.vue"),
    meta: {
      title: '首页', //--路由元信息
      transition: 'animate__bounceIn' //--过渡动画
    },
  },
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),//修改后
  routes
})

// 登录：做动态路由生成和鉴权
// 访问A页面：做动态路由生成和鉴权(token)
// 刷新访问B：做动态路由生成和鉴权(token)
  //不是所有的页面都需要来验证登录
  //比如 登录 注册 都属于白名单

// 获取token
function getToken() {
  return sessionStorage.getItem('token')
}

const whiteList = ['/page/login','/page/404','/page/401'] //401:访问无权限的展示页面 ,'/page/index'
router.beforeEach((to,from,next)=>{
  // next() 放行
  // next(false) 取消用户导航行为
  // next(路由) 重定向到某个路由


  //基于session验证
  //白名单 放行
  if(whiteList.includes(to.path)){
    return next()
  }
  
  //简单验证用户是否登录，若未登录则强制跳回login
  //如果用户刷新浏览器或者点击地址栏激活，会丢失store信息，所以也强制跳回login
  /*
  if(to.path=='/index' && !useUserStore().isLogin){
    return next('/')
  }
  */

  /*
  //基于token验证
  /白名单 放行
  if(whiteList.includes(to.path)){
    if(to.path=='/page/login' && getToken()){
      return next('/page/index')
    }
    return next()
  }

  //需要验证 已有token => 是否加载过菜单
  if(!getToken()){
    // 重定向到login
    return next('/page/login')
    //return next('/page/login?redirect=' + to.fullPath)
  }
  */

  next();
})

export default router
