import {defineStore} from 'pinia'

export const useUserStore = defineStore('useUserStore',{
    state:() => ({
        id:0,//1
        name:'', //登录账号,如Admin
        role:'', //Admin
        img:'', //头像地址
        isread:1,//1表示已读，没有未读
        isLogin:false,
    }),
    //computed 修饰一些值
    getters:{

    },
    //methods 可以做同步异步 提交state
    actions:{
       changeLogin(state=true){
        this.isLogin = state
       }
    }

})