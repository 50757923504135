import {defineStore} from 'pinia'

export const useSettingStore = defineStore('useSettingStore',{
    state:() => ({
        originUrl:'',//http://cycra.saibo.net.cn
        flagUrl:'/',
        apiUrl:'',//导入测试用/api1 打包设为''
        //--------------------------------------------------------------
        formLabelWidth:'120px',
        contentHeight:document.documentElement.clientHeight - 260, //通用主内容高度
        contentHeight_testList3:document.documentElement.clientHeight - 310,//考试列表page3(标题和功能区分行)主内容高度
        inputMaxLength:50,
        resHandleException:'', //请求失败，请重新登录
        mainFnList:[
            {
                'index': '1',
                'title': '机构管理',
                'icon': `Menu`,
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '1-1', 'title': '机构列表', comName: 'a11', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '2',
                'title': '学员管理',
                'icon': 'Avatar',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '2-1', 'title': '学员列表', comName: 'a21', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '3',
                'title': '考试管理',
                'icon': 'Tickets',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '3-1', 'title': '考试列表', comName: 'a310', 'parameter': 'LM_User', 'icon': '' },
                    //{ 'index': '3-2', 'title': '学员成绩', comName: 'a32', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '4',
                'title': '试卷管理',
                'icon': 'Coin',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '4-1', 'title': '试卷列表', comName: 'a41', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:false,
            },
            {
                'index': '5',
                'title': '题库管理',
                'icon': 'Coin',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '5-1', 'title': '题库列表', comName: 'a510', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '6',
                'title': '通知公告',
                'icon': 'TakeawayBox',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '6-1', 'title': '通知列表', comName: 'a61', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '7',
                'title': '系统用户',
                'icon': 'User',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '7-1', 'title': '用户管理', comName: 'a71', 'parameter': 'LM_User', 'icon': '' },
                    { 'index': '7-2', 'title': '个人中心', comName: 'a72', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '8',
                'title': '日志管理',
                'icon': 'Document',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '8-1', 'title': '系统日志', comName: 'a81', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '9',
                'title': '系统设置',
                'icon': 'Setting',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '9-1', 'title': '科目类别', comName: 'a91', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
        ],
        companyFnList:[
            {
                'index': '1',
                'title': '学员管理',
                'icon': 'Avatar',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '1-1', 'title': '学员列表', comName: 'c11', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '2',
                'title': '考试管理',
                'icon': 'Tickets',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '2-1', 'title': '考试列表', comName: 'c210', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '3',
                'title': '通知公告',
                'icon': 'TakeawayBox',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '3-1', 'title': '通知列表', comName: 'c310', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '4',
                'title': '个人中心',
                'icon': 'Setting',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '4-1', 'title': '个人信息', comName: 'c41', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
        ],
        studentFnList:[
            {
                'index': '1',
                'title': '在线考试',
                'icon': 'Menu',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '1-1', 'title': '考试列表', comName: 's110', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            /*
            {
                'index': '2',
                'title': '我的试卷',
                'icon': 'Tickets',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '2-1', 'title': '我的试卷', comName: 's21', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            */
            {
                'index': '3',
                'title': '通知公告',
                'icon': 'TakeawayBox',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '3-1', 'title': '通知列表', comName: 's310', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            {
                'index': '4',
                'title': '个人中心',
                'icon': 'Setting',
                subMenu: true,
                subMenuVisible: false,
                parameter: 'LM_UserManagement',
                subMenuList: [
                    { 'index': '4-1', 'title': '个人信息', comName: 's41', 'parameter': 'LM_User', 'icon': '' },
                ],
                indexPageShow:true,
            },
            
        ],
    }),
    //computed 修饰一些值
    getters:{

    },
    //methods 可以做同步异步 提交state
    actions:{
        /*
        setCurrent (num:number) {
            this.current = num
        }
        */
    }

})